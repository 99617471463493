import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { connect } from "react-redux"

import { getQuestions } from "../actions/challenge"

import { Link, navigate } from "gatsby"
import Page from "../components/Page"
import Centerer from "../components/Centerer"
import Button from "../components/Button"

class Lesson extends Component {
  componentDidMount() {
    const { data, getQuestionsAction } = this.props
    const pageData = data.markdownRemark.frontmatter
    const allChallenges = JSON.parse(pageData.challenges)

    getQuestionsAction(allChallenges)
  }

  render() {
    const { data, firstQuestionSlug } = this.props
    const pageData = data.markdownRemark.frontmatter

    return (
      <Page>
        <Centerer>
          <h1>Lesson: {pageData.title}</h1>
          <Link to="/">Back</Link>

          {firstQuestionSlug && (
            <Button
              onClick={() => {
                navigate(firstQuestionSlug)
              }}
            >
              GO!
            </Button>
          )}
        </Centerer>
      </Page>
    )
  }
}

Lesson.defaultProps = {
  firstQuestionSlug: null,
  getQuestionsAction: () => {},
}

Lesson.propTypes = {
  firstQuestionSlug: PropTypes.string,
  getQuestionsAction: PropTypes.func,
}

const mapStateToProps = (state, props) => {
  const firstQuestion =
    state.challenge.questions.length && state.challenge.questions[0]
  const firstQuestionSlug = firstQuestion
    ? `${props.pageContext.slug}${firstQuestion}`
    : null

  return {
    firstQuestionSlug,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getQuestionsAction: lessonChallenges =>
      dispatch(getQuestions(lessonChallenges)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Lesson)

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        challenges
      }
    }
  }
`
